<template>
  <v-container fluid>
    <QPointsTab :activeTab="'dashboard'" />
    <v-row dense class="mt-6">
      <v-col lg="3" md="3" sm="6"
        v-if="checkReadPermission($modules.marketing['sms'].slug)"
        style="font-weight: 500; font-size: 16px; color: #112A46" >
        <router-link :to="''">
          <v-card class="mx-auto shadow-0 mt-2" style="border: 1px solid #DCDCDC">
            <v-card-text class="font-weight-bold">
              <v-row  class="d-flex" justify="center">
                <v-col md="12">
                    <v-btn fab elevation="0" x-small color="#0051FF1A">
                    <EInvoiceMembershipIcon />
                    </v-btn>
                    <div class="mt-5 row">
                        <div class="col text-left">
                            Total Orders
                        </div>
                        <div class="col text-right blue-text" style="font-weight: 500">
                            12345
                        </div>
                    </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
     <v-col lg="3" md="3" sm="6"
        v-if="checkReadPermission($modules.marketing['sms'].slug)"
        style="font-weight: 500; font-size: 16px; color: #112A46" >
        <router-link :to="''">
          <v-card class="mx-auto shadow-0 mt-2" style="border: 1px solid #DCDCDC">
            <v-card-text class="font-weight-bold">
              <v-row  class="d-flex" justify="center">
                <v-col md="12">
                    <v-btn fab elevation="0" x-small color="#00C8FF1A">
                    <EInvoiceMembershipIcon />
                    </v-btn>
                    <div class="mt-5 row">
                        <div class="col text-left">
                           QPoints Customers
                        </div>
                        <div class="col text-right blue-text" style="font-weight: 500">
                            12345
                        </div>
                    </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
      <v-col lg="3" md="3" sm="6"
        v-if="checkReadPermission($modules.marketing['sms'].slug)"
        style="font-weight: 500; font-size: 16px; color: #112A46" >
        <router-link :to="''">
          <v-card class="mx-auto shadow-0 mt-2" style="border: 1px solid #DCDCDC">
            <v-card-text class="font-weight-bold">
              <v-row  class="d-flex" justify="center">
                <v-col md="12">
                    <v-btn fab elevation="0" x-small color="#00C5001A">
                    <EInvoiceMembershipIcon />
                    </v-btn>
                    <div class="mt-5 row">
                        <div class="col text-left">
                          Points Earned
                        </div>
                        <div class="col text-right blue-text" style="font-weight: 500">
                            12345
                        </div>
                    </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
      <v-col lg="3" md="3" sm="6"
        v-if="checkReadPermission($modules.marketing['sms'].slug)"
        style="font-weight: 500; font-size: 16px; color: #112A46" >
        <router-link :to="''">
          <v-card class="mx-auto shadow-0 mt-2" style="border: 1px solid #DCDCDC">
            <v-card-text class="font-weight-bold">
              <v-row  class="d-flex" justify="center">
                <v-col md="12">
                    <v-btn fab elevation="0" x-small color="#FF72001A">
                    <EInvoiceMembershipIcon />
                    </v-btn>
                    <div class="mt-5 row">
                        <div class="col text-left">
                          Points Consumed
                        </div>
                        <div class="col text-right blue-text" style="font-weight: 500">
                            12345
                        </div>
                    </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </router-link>
      </v-col>
     
    </v-row>
  </v-container>
</template>

<script>
import QPointsTab from "@/components/QPoints/QPointsTab.vue";
import EInvoiceMembershipIcon from "@/assets/images/marketing/einvoice-mail.svg";
export default {
  components: {
    QPointsTab,
    EInvoiceMembershipIcon,
  },
  methods: {
   
   
  },
};
</script>
