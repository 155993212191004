<template>
    <div class="qpoints-tabs">
        <v-row dense >
          <v-col cols="12" xl="12" lg="12">
              <div class="d-flex justify-space-between " >
              <div class="d-flex bordered qp-tab-nav">
                  <div class="d-flex p-2">
                  <!-- <template >
                      <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/qpoints/dashboard')">
                      <SvgIcon :class="`text-xl text-thin-black ${activeTab == 'dashboard'?'qp-tab-nav-is-active':''}`" text="Dashboard">
                          <template v-slot:icon>
                          <DashboardIcon />
                          </template>
                      </SvgIcon>
                      </div>
                      <v-divider
                          vertical style="background-color: rgba(220, 220, 220, 1)"
                      ></v-divider>
                  </template> -->

                  <template v-if="checkWritePermission($modules.trainers.sales.slug)">
                      <div class="nv_item d-flex p-4 pointer "  @click="gotoPage('/qpoints/logs')">
                      <SvgIcon :class="`text-xl text-thin-black ${activeTab == 'logs'?'qp-tab-nav-is-active':''}`" text="Logs" >
                          <template v-slot:icon>
                          <PeopleIcon />
                          </template>
                      </SvgIcon>
                      </div>
                      <v-divider
                          vertical style="background-color: rgba(220, 220, 220, 1)"
                      ></v-divider>
                  </template>

                  <template  v-if="checkWritePermission($modules.trainers.dashboard.slug)">
                      <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/qpoints/config')">
                      <SvgIcon :class="`text-xl text-thin-black ${activeTab == 'config'?'qp-tab-nav-is-active':''}`" text="Config" >
                          <template v-slot:icon>
                          <ConfigIcon />
                          </template>
                      </SvgIcon>
                      </div>
                  </template>
                  </div>
              </div>
              </div>
          </v-col>
        </v-row>
        <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>
    </div>
</template>
<script>
// import DashboardIcon from '@/assets/images/misc/dashboard-icon.svg';
import PeopleIcon from '@/assets/images/customers/people-icon.svg';
import ConfigIcon from "@/assets/images/trainers/cog_icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  props: { 
    activeTab: { type: String, default:"dashboard"}
  },
  components: {
    SvgIcon,
    ConfigIcon,
    PeopleIcon,
    // DashboardIcon,
  },
  data() {
    return {};
  },
  methods: {
    gotoPage(route){
      console.log(this.activeTab);
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
      
    },
  }
}
</script>